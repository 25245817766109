/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Poppins Fallback';src: local("Arial");ascent-override: 93.62%;descent-override: 31.21%;line-gap-override: 8.92%;size-adjust: 112.16%
}.__className_5d6309 {font-family: 'Poppins', 'Poppins Fallback';font-style: normal
}.__variable_5d6309 {--poppins-font: 'Poppins', 'Poppins Fallback'
}

/* vietnamese */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/057583820dbfe2d1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4f009f683e30da2d-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/94b45cb581d25b69-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/057583820dbfe2d1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4f009f683e30da2d-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/94b45cb581d25b69-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Darker Grotesque Fallback';src: local("Arial");ascent-override: 130.90%;descent-override: 36.55%;line-gap-override: 0.00%;size-adjust: 80.98%
}.__className_c7699f {font-family: 'Darker Grotesque', 'Darker Grotesque Fallback';font-style: normal
}.__variable_c7699f {--darker-font: 'Darker Grotesque', 'Darker Grotesque Fallback'
}

:root {
  --color-attention0: #fff9e8;
  --color-attention1: hsl(48 100% 90%);
  --color-attentionActive: hsl(48 100% 75%);
  --color-attention: #ffda05;
  --color-attentionHover: hsl(51 100% 46%);
  --color-attentionDark1: hsl(51 100% 45%);
  --color-bottle: #075d52;
  --color-bottleLight: #f1fffe;
  --color-potus: #00a69c;
  --color-error: #f25c5a;
  --color-errorBackground: hsl(1 85% 90%);
  --color-errorActive: hsl(1 85% 55%);
  --color-red5: #af110e;
  --color-dark3: #cfdbe2;
  --color-dark2: hsl(202 25% 45%);
  --color-dark: #1e2e28;
  --color-green: hsl(161 18% 69%);
  --color-greenText: hsl(161 18% 45%);
  --color-lightGreen: hsl(86 21% 94%);
  --color-pink: #f5e8e3;
  --color-lightGrey: hsl(228 24% 95%);
  --color-gray0: #ecf4f1;
  --color-gray020: rgba(236, 244, 241, 0.2);
  --color-gray020NoAlpha: #fcfcfc;
  --color-gray2: #b2c2bc;
  --color-gray3: #76807c;
  --color-gray4: #c9ddd6;
  --color-gray0Hover: #dbebe5;
  --color-gray: #9fa09f;
  --color-white: #ffffff;
  --color-white0: #fffef9;
  --color-black: #000000;
  --color-red1: #fdd0cf;
  --color-teja: #de8069;
  --color-tejaHover: hsl(12 64% 44%);
  --color-tejaLight: #ffdfdf;
  --color-marmolClaro: #ffecea;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  margin: 0;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
  height: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

.body {
  line-height: 28px;
  font-size: 24px;
  font-weight: 500;
}

.body a {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  -webkit-text-decoration-color: var(--color-dark);
          text-decoration-color: var(--color-dark);
  text-decoration-thickness: 1px;
}

.body a:hover {
  color: var(--color-teja);
  -webkit-text-decoration-color: var(--color-teja);
          text-decoration-color: var(--color-teja);
  text-decoration-thickness: 3px;
}

.h1 {
  font-family: var(--poppins-font);
  font-size: 32px;
  line-height: 1.3;
  font-weight: 700;
}

,
@media (min-width:900px) {
  .h1 {
    font-size: 48px;
  }
}

@media (min-width: 1200px) {
  .h1 {
    font-size: 60px;
  }
}

s::before,
s::after {
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

s::before {
  content: ' [inicio de texto borrado] ';
}

s::after {
  content: ' [fin de texto borrado] ';
}

.onHoldGridContainer {
  padding-top: 40px;
}

