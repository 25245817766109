:root {
  --color-attention0: #fff9e8;
  --color-attention1: hsl(48 100% 90%);
  --color-attentionActive: hsl(48 100% 75%);
  --color-attention: #ffda05;
  --color-attentionHover: hsl(51 100% 46%);
  --color-attentionDark1: hsl(51 100% 45%);
  --color-bottle: #075d52;
  --color-bottleLight: #f1fffe;
  --color-potus: #00a69c;
  --color-error: #f25c5a;
  --color-errorBackground: hsl(1 85% 90%);
  --color-errorActive: hsl(1 85% 55%);
  --color-red5: #af110e;
  --color-dark3: #cfdbe2;
  --color-dark2: hsl(202 25% 45%);
  --color-dark: #1e2e28;
  --color-green: hsl(161 18% 69%);
  --color-greenText: hsl(161 18% 45%);
  --color-lightGreen: hsl(86 21% 94%);
  --color-pink: #f5e8e3;
  --color-lightGrey: hsl(228 24% 95%);
  --color-gray0: #ecf4f1;
  --color-gray020: rgba(236, 244, 241, 0.2);
  --color-gray020NoAlpha: #fcfcfc;
  --color-gray2: #b2c2bc;
  --color-gray3: #76807c;
  --color-gray4: #c9ddd6;
  --color-gray0Hover: #dbebe5;
  --color-gray: #9fa09f;
  --color-white: #ffffff;
  --color-white0: #fffef9;
  --color-black: #000000;
  --color-red1: #fdd0cf;
  --color-teja: #de8069;
  --color-tejaHover: hsl(12 64% 44%);
  --color-tejaLight: #ffdfdf;
  --color-marmolClaro: #ffecea;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  margin: 0;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
  height: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

.body {
  line-height: 28px;
  font-size: 24px;
  font-weight: 500;
}

.body a {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--color-dark);
  text-decoration-thickness: 1px;
}

.body a:hover {
  color: var(--color-teja);
  text-decoration-color: var(--color-teja);
  text-decoration-thickness: 3px;
}

.h1 {
  font-family: var(--poppins-font);
  font-size: 32px;
  line-height: 1.3;
  font-weight: 700;
}

,
@media (min-width:900px) {
  .h1 {
    font-size: 48px;
  }
}

@media (min-width: 1200px) {
  .h1 {
    font-size: 60px;
  }
}

s::before,
s::after {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

s::before {
  content: ' [inicio de texto borrado] ';
}

s::after {
  content: ' [fin de texto borrado] ';
}

.onHoldGridContainer {
  padding-top: 40px;
}
